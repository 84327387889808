<template>
  <chat
    class="pa-3"
    title="Electronic Application Chat"
    ref="chat"
    flat
    :checkboxes="checkboxes"
    :pusher-id="`private-${id}-ElectronicApplication`"
    :messages="messages"
    :create-func="sendChatMessage"
    @remove-message="removeFromMessages"
    @new-note="newNote"
  />
</template>

<script setup>
import Chat from "@/components/shared/chat/Chat.vue";
import {
  setNoteFromRequest,
  Note,
  NoteToCreateRequest
} from "@/factories/NoteFactory";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useEappViewStore } from "@/stores/eapp-view";
import { computed } from "vue";

const checkboxes = [
  {
    key: "sendEmail",
    label: "Send Email",
    default: true,
    dataTestid: "email"
  }
];

const eappView = useEappViewStore();
const user = useUserStore();
const snackbar = useSnackbarStore();

const { notes, insured, id } = storeToRefs(eappView);

const messages = computed(() => {
  const name = insured.value?.name ? insured.value.name + "'s" : "this";
  return [
    {
      note: `Hi ${user.loginable.name}, you can make notes on ${name} Electronic Application here. These notes are not visible by clients.`,
      ownable: { name: "BackNine" }
    },
    ...notes.value.toSorted(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    )
  ];
});

function removeFromMessages(message) {
  const index = notes.value.findIndex(({ id }) => id === message.id);
  notes.value.splice(index, 1);
}
function newNote({ note: rawNote }) {
  notes.value.push(setNoteFromRequest(rawNote));
}
async function sendChatMessage(note) {
  const newNote = Note({
    documents: note.files,
    email: note.sendEmail ? 1 : 0,
    phoneCall: Boolean(note.sendPhoneCall),
    note: note.message,
    notableId: id.value,
    notableType: "ElectronicApplication"
  });

  try {
    await createNote(NoteToCreateRequest(newNote));
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}
</script>
