<template>
  <v-fade-transition mode="out-in">
    <v-col v-if="loading" key="loader">
      <v-row dense>
        <v-col cols="12">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader type="table" />
        </v-col>
      </v-row>
    </v-col>
    <v-row v-else class="width-full" dense key="loaded">
      <v-col cols="12" lg="12" class="pb-0">
        <party-view-card />
      </v-col>
      <v-col cols="12" class="py-0">
        <party-view-actions />
      </v-col>
      <v-col cols="12" lg="12" class="pt-0">
        <v-card flat tile>
          <v-tabs v-model="activeTab" show-arrows class="no-bottom-radius">
            <v-tab
              v-for="tab in tabs"
              :key="'tab' + tab.title"
              class="text-none"
              :data-testid="`tab-${tab.title}`"
            >
              <v-icon class="pr-1">{{ tab.icon }}</v-icon>
              <v-badge v-if="tab.badgeProps" v-bind="tab.badgeProps">
                {{ tab.title }}
              </v-badge>
              <template v-else>{{ tab.title }}</template>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item
              v-for="tab in tabs"
              :key="'tab' + tab.title"
              :eager="tab.eager"
            >
              <component :is="tab.component" v-bind="tab.componentProps" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-fade-transition>
</template>

<script setup>
import PartyViewCard from "@/components/parties/PartyViewCard.vue";
import PartyViewChat from "@/components/parties/PartyViewChat.vue";
import PartyViewActions from "@/components/parties/PartyViewActions.vue";

import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiMessage } from "@mdi/js";
import { computed, defineProps, markRaw, onBeforeUnmount, ref } from "vue";
import { usePartyView } from "@/stores/party-view";
import { useRouter } from "@/composables/compatible.composables";

const props = defineProps({
  type: {
    type: String,
    required: true,
    validates: value => ["Individual", "Entity"].includes(value)
  },
  id: { type: [Number, String], required: true }
});

const router = useRouter();
const partyView = usePartyView();
const instance = useInstanceStore();
const snackbar = useSnackbarStore();

const { name } = storeToRefs(partyView);
const { breadcrumb } = storeToRefs(instance);

const loading = ref(false);
const activeTab = ref(0);

const head = useHead({
  title: `${props.type} Viewer`
});

const tabs = computed(() => [
  { title: "Chat", component: markRaw(PartyViewChat), icon: mdiMessage }
]);

async function getParty() {
  loading.value = true;
  try {
    await partyView.initialize(props.id, props.type);
    breadcrumb.value = name.value;
    head.patch({ title: name.value });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

onBeforeUnmount(() => (breadcrumb.value = ""));

getParty();
</script>
