import Vue from "vue";
import VueRouter from "vue-router";

import multiguard from "vue-router-multiguard";

Vue.use(VueRouter);

import SignUpOnboarding from "@/components/auth/SignUpOnboarding.vue";
import SignUp from "@/components/auth/SignUp.vue";
import AppHome from "@/components/home/Home.vue";
import Quoting from "@/views/Quoting.vue";
import QuoteAndApply from "@/views/QuoteAndApply.vue";
import TableView from "@/views/TableView.vue";
import CaseManagement from "@/views/CaseManagement.vue";
import Commissions from "@/views/Commissions.vue";
import Applications from "@/views/Applications.vue";
import ChangePassword from "@/components/auth/ChangePassword.vue";

import SettingsView from "@/components/settings/SettingsView.vue";
import AdvisorProfileView from "@/components/advisor-profile/AdvisorProfileView.vue";
import PersonalReportView from "@/components/reports/PersonalReportView.vue";
import ScorecardReport from "@/components/reports/ScorecardReport.vue";
import BackNineReportView from "@/components/reports/BackNineReportView.vue";
import QuoteAndApplyReport from "@/components/reports/QuoteAndApplyReport.vue";
import CaseManagementReportView from "@/components/reports/CaseManagementReportView.vue";
import MarketingManagerReportView from "@/components/reports/MarketingManagerReportView.vue";
import CreateQuoteRequest from "@/components/quotes/quote-request/CreateQuoteRequest.vue";
import QuoteView from "@/components/quotes/QuoteView.vue";
import ImpairedRiskQuoteCreate from "@/components/impaired-risk-quote/ImpairedRiskQuoteCreate.vue";
import ImpairedRiskQuoteView from "@/components/impaired-risk-quote/ImpairedRiskQuoteView.vue";
import ApprovedDomainView from "@/components/approved-domain/ApprovedDomainView.vue";
import FormMappingTablesView from "@/components/form-mapping/FormMappingTablesView.vue";
import FormMappingView from "@/components/form-mapping/FormMappingView.vue";
import AppointmentCreate from "@/components/appointments/AppointmentCreate.vue";
import AppointmentView from "@/components/appointments/AppointmentView.vue";
import AdvisorCreate from "@/components/advisors/AdvisorCreate.vue";

import InboundCall from "@/components/InboundCall.vue";
import Reader from "@/components/help-center/HelpCenterReader.vue";
import HelpCenterCreator from "@/components/help-center/HelpCenterCreator.vue";
import HelpCenterSeries from "@/components/help-center/HelpCenterSeries.vue";
import HelpCenter from "@/components/help-center/HelpCenter.vue";
import LeadView from "@/components/leads/LeadView.vue";
import LeadCreate from "@/components/leads/LeadCreate.vue";
import MarketingView from "@/components/marketing/MarketingView.vue";
import ProductView from "@/components/products/ProductView.vue";
import ProductCreate from "@/components/products/ProductCreate.vue";
import ProductsTable from "@/components/products/ProductsTable.vue";
import TransactionCreate from "@/components/commissions/TransactionCreate.vue";
import EmployeeView from "@/components/employees/EmployeeView.vue";
import BackNineContactsTable from "@/components/contact/BackNineContactsTable.vue";
import PayPeriodView from "@/components/pay-period/PayPeriodView.vue";
import BackNineStatement from "@/components/commissions/back-nine-statement/BackNineStatement.vue";
import StatementCreate from "@/components/commissions/StatementCreate.vue";
import QueuedTransactions from "@/components/commissions/QueuedTransactions.vue";
import BackNineStatementsTable from "@/components/commissions/BackNineStatementsTable.vue";
import PaymentCreate from "@/components/commissions/PaymentCreate.vue";
import BackNineCaseCommissionsTable from "@/components/commissions/BackNineCaseCommissionsTable.vue";
import PartyView from "@/components/parties/PartyView.vue";
import ContractPartyView from "@/components/contract-parties/contract-party-view/ContractPartyView.vue";
import ContractPartyCreate from "@/components/contract-parties/ContractPartyCreate.vue";
import CarrierView from "@/components/carriers/CarrierView.vue";
import PersonnelCreate from "@/components/personnel/PersonnelCreate.vue";
import PersonnelView from "@/components/personnel/PersonnelView.vue";
import CasesTable from "@/components/cases/CasesTable.vue";
import CaseCreate from "@/components/cases/case-create/CaseCreate.vue";
import CaseCreateInformal from "@/components/cases/case-create/CaseCreateInformal.vue";
import CaseSubmission from "@/components/cases/case-create/CaseSubmission.vue";
import CaseView from "@/components/cases/case-view/CaseView.vue";
import ElectronicApplicationView from "@/components/electronic-applications/ElectronicApplicationView.vue";
import ApplicationRequest from "@/components/forms/ApplicationRequest.vue";
import HolidaysView from "@/components/reports/HolidaysView.vue";
import { useUserStore } from "@/stores/user";
import { AVAILABLE_SERIES } from "@/composables/series-composable";
import SignIn from "@/components/auth/SignIn.vue";
import TwoFactorConfirmation from "@/components/auth/TwoFactorConfirmation.vue";
import TwoFactorEnrollmentRequired from "@/components/auth/TwoFactorEnrollmentRequired.vue";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import CustomChatTemplateCreator from "@/components/custom-chat-templates/CustomChatTemplateCreator.vue";

import AuthLayout from "@/layouts/AuthLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import BareLayout from "@/layouts/BareLayout.vue";

import {
  authGuard,
  onboardingGuard,
  twoFactorConfirmationRequiredGuard,
  twoFactorEnrollmentRequiredGuard
} from "@/guards/auth.guard.js";
import {
  formMappingGuard,
  accountingGuard,
  scorecardGuard
} from "@/guards/permissions.guard.js";
import { isEmployeeGuard } from "@/guards/is-employee.guard.js";
import { groupTwoGuard } from "@/guards/group.guard.js";
import { commissionsEnabledGuard } from "@/guards/commissions-enabled.guard.js";
import { showBacknineGuard } from "@/guards/show-backnine.guard.js";
import { hasApprovedDomainGuard } from "@/guards/has-approved-domain.guard.js";

import { getMe } from "@/api/boss.service";
import { handleRedirectAfterAuthentication } from "@/api/auth.service";

const routes = [
  {
    path: "/sign-in",
    name: "SignIn",
    components: { default: SignIn, layout: AuthLayout },
    meta: { bypassStdAuthGuard: true },
    beforeEnter: async (to, _, next) => {
      try {
        await getMe();
        await handleRedirectAfterAuthentication(router);
      } catch (e) {
        next();
      }
    }
  },
  {
    path: "/advisor-verification/:uuid",
    meta: { bypassStdAuthGuard: true },
    beforeEnter: async (to, _, next) => {
      if (!to.params.uuid) next({ name: "SignIn" });
      const attestationRoute = `/auth/attestation?eapp_id=${to.params.uuid}`;
      try {
        await getMe();
        window.location.replace(attestationRoute);
      } catch (e) {
        //do nothing
        next({ name: "SignIn", query: { redirect: attestationRoute } });
      }
    }
  },
  {
    path: "/2fa",
    name: "TwoFactorConfirmation",
    components: { default: TwoFactorConfirmation, layout: AuthLayout },
    beforeEnter: twoFactorConfirmationRequiredGuard,
    meta: { bypassStdAuthGuard: true }
  },
  {
    path: "/2fa-required",
    name: "TwoFactorEnrollmentRequired",
    components: { default: TwoFactorEnrollmentRequired, layout: AuthLayout },
    beforeEnter: twoFactorEnrollmentRequiredGuard,
    meta: { bypassStdAuthGuard: true }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    components: { default: ForgotPassword, layout: AuthLayout },
    meta: { bypassStdAuthGuard: true }
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    components: { default: ChangePassword, layout: AuthLayout },
    meta: { bypassStdAuthGuard: true },
    beforeEnter: (to, _, next) => {
      if (to.query.reset_password_token) next();
      else next({ name: "SignIn" });
    },
    props: {
      default: route => ({
        resetPasswordToken: route.query.reset_password_token
      })
    }
  },
  {
    path: "/sign-up",
    name: "SignUp",
    components: { default: SignUp, layout: AuthLayout },
    beforeEnter: async (to, _, next) => {
      const signUpStore = await import("@/stores/sign-up");
      const store = signUpStore.useSignupStore();
      store.$reset();
      store.setData(to);
      next();
    },
    meta: { bypassStdAuthGuard: true }
  },
  {
    path: "/onboarding-sign-up",
    name: "OnboardingSignup",
    components: { default: SignUpOnboarding, layout: AuthLayout },
    beforeEnter: onboardingGuard,
    meta: { bypassStdAuthGuard: true }
  },
  {
    path: "/join/:referralCode",
    name: "ReferralSignUp",
    components: { default: SignUp, layout: AuthLayout },
    beforeEnter: async (to, _, next) => {
      if (!to.params.referralCode) return next({ name: "SignUp" });
      const signUpStore = await import("@/stores/sign-up");
      const store = signUpStore.useSignupStore();
      store.$reset();
      store.setData(to);
      await store.fetchCode();
      next();
    },
    meta: { bypassStdAuthGuard: true }
  },
  // home
  {
    path: "/",
    name: "Home",
    components: { default: AppHome, layout: AppLayout }
  },

  // applications
  {
    path: "/applications",
    name: "Applications",
    components: { default: Applications, layout: AppLayout }
  },
  {
    path: "/applications/request",
    name: "ApplicationRequest",
    components: { default: ApplicationRequest, layout: AppLayout },
    beforeEnter: showBacknineGuard,
    meta: {
      breadcrumb: "Create Application",
      breadcrumbs: [
        {
          text: "Forms & Applications",
          to: { name: "Applications" }
        }
      ]
    }
  },

  // reports
  {
    path: "/reports/personal",
    name: "PersonalReportView",
    components: { default: PersonalReportView, layout: AppLayout }
  },
  {
    path: "/reports/scorecard",
    name: "ScorecardReport",
    components: { default: ScorecardReport, layout: AppLayout },
    beforeEnter: scorecardGuard
  },
  {
    path: "/reports/holidays",
    name: "HolidaysView",
    components: { default: HolidaysView, layout: AppLayout },
    beforeEnter: groupTwoGuard
  },
  {
    path: "/reports/backnine",
    name: "BackNineReportView",
    components: { default: BackNineReportView, layout: AppLayout },
    beforeEnter: groupTwoGuard
  },
  {
    path: "/reports/quote-and-apply",
    name: "QuoteAndApplyReport",
    components: { default: QuoteAndApplyReport, layout: AppLayout },
    beforeEnter: groupTwoGuard
  },
  {
    path: "/reports/case-management",
    name: "CaseManagementReportView",
    components: { default: CaseManagementReportView, layout: AppLayout },
    beforeEnter: groupTwoGuard
  },
  {
    path: "/reports/marketing-manager",
    name: "MarketingManagerReportView",
    components: { default: MarketingManagerReportView, layout: AppLayout },
    beforeEnter: groupTwoGuard
  },

  // Quotes
  {
    path: "/quotes",
    name: "Quotes",
    components: { default: Quoting, layout: AppLayout },
    beforeEnter: showBacknineGuard
  },
  {
    path: "/quotes/create",
    name: "CreateQuote",
    props: {
      default: route => ({ ...route.params })
    },
    components: { default: CreateQuoteRequest, layout: AppLayout },
    meta: {
      breadcrumb: "Create Quote Request",
      breadcrumbs: [
        {
          text: "Quotes",
          to: { name: "Quotes" }
        }
      ]
    },
    beforeEnter: showBacknineGuard
  },
  {
    path: "/quotes/:id",
    name: "QuoteView",
    components: { default: QuoteView, layout: AppLayout },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Quotes",
          to: { name: "Quotes" }
        }
      ]
    },
    props: {
      default: route => ({
        id: +route.params.id,
        page: route.query.page,
        highlightTodo: route.query["highlight-todo"]
          ? +route.query["highlight-todo"]
          : null
      })
    },
    beforeEnter: showBacknineGuard
  },

  // impaired risk quotes
  {
    path: "/impaired-risk-quotes/create",
    beforeEnter: showBacknineGuard,
    name: "ImpairedRiskQuoteCreate",
    components: { default: ImpairedRiskQuoteCreate, layout: AppLayout },
    meta: {
      breadcrumb: "Create Impaired Risk Quote",
      breadcrumbs: user => {
        let to = { name: "Quotes" };
        if (user.loginable.is_case_manager) {
          to = { name: "Tables", query: { page: "underwriting" } };
        }
        return [{ text: "Quoting", to }];
      }
    }
  },
  {
    path: "/impaired-risk-quotes/:id",
    name: "ImpairedRiskQuoteView",
    beforeEnter: showBacknineGuard,
    components: { default: ImpairedRiskQuoteView, layout: AppLayout },
    props: {
      default: route => ({
        id: +route.params.id,
        page: route.query?.page,
        offer: route.query?.offer,
        highlightTodo: route.query["highlight-todo"]
          ? +route.query["highlight-todo"]
          : null
      })
    },
    meta: {
      useStateVal: true,
      breadcrumbs: user => {
        let to = { name: "Quotes" };
        if (user.loginable.is_case_manager) {
          to = { name: "Tables", query: { page: "underwriting" } };
        }
        return [{ text: "Quoting", to }];
      }
    }
  },

  // quote-and-apply
  {
    path: "/quote-and-apply",
    name: "QuoteAndApply",
    components: { default: QuoteAndApply, layout: AppLayout },
    props: {
      default: route => ({ view: route.query.view })
    }
  },
  {
    path: "/quote-and-apply/:id",
    name: "ApprovedDomains",
    props: {
      default: route => ({
        id: route.params.id,
        page: route.query.page,
        scrollTo: route.query["scroll-to"]
      })
    },
    components: { default: ApprovedDomainView, layout: AppLayout },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Quote & Apply",
          to: { name: "QuoteAndApply" }
        }
      ]
    }
  },

  // forms
  {
    path: "/forms",
    name: "FormMappingForms",
    components: { default: FormMappingTablesView, layout: AppLayout },
    beforeEnter: formMappingGuard
  },
  {
    path: "/forms/:id",
    name: "FormMappingEditor",
    components: { default: FormMappingView, layout: AppLayout },
    props: { default: true },
    meta: {
      useStateVal: true,
      useBreadcrumbSlot: true,
      breadcrumbs: [
        {
          text: "Forms",
          to: { name: "FormMappingForms" }
        }
      ]
    },
    beforeEnter: formMappingGuard
  },

  // appointments
  {
    path: "/appointments/create",
    name: "AppointmentCreate",
    components: { default: AppointmentCreate, layout: AppLayout },
    beforeEnter: groupTwoGuard,
    meta: {
      breadcrumb: "Create Appointment",
      breadcrumbs: [
        {
          text: "Appointments",
          to: { name: "Tables", query: { page: "appointments" } }
        }
      ]
    }
  },
  {
    path: "/appointments/:id",
    name: "AppointmentView",
    components: { default: AppointmentView, layout: AppLayout },
    props: {
      default: route => ({
        id: route.params.id,
        page: route.query.page,
        highlightTodo: route.query["highlight-todo"]
          ? +route.query["highlight-todo"]
          : null
      })
    },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Appointments",
          to: { name: "Tables", query: { page: "appointments" } }
        }
      ]
    }
  },

  // advisors
  {
    path: "/advisors/create",
    name: "AdvisorCreate",
    components: { default: AdvisorCreate, layout: AppLayout },
    meta: {
      breadcrumb: "Create Advisor",
      breadcrumbs: [
        {
          text: "Advisors",
          to: { name: "Tables", query: { page: "advisors" } }
        }
      ]
    }
  },

  // profile
  {
    path: "/profile",
    name: "LoggedInUserProfile",
    components: { default: AdvisorProfileView, layout: AppLayout },
    props: {
      default: route => {
        const user = useUserStore();
        return {
          id: user.loginable.id,
          type: user.loginable.type,
          page: route.query?.page,
          resource: route.query?.resource
        };
      }
    }
  },
  {
    path: "/agents/:id",
    name: "AgentView",
    components: { default: AdvisorProfileView, layout: AppLayout },
    props: {
      default: route => ({
        type: "Agent",
        id: +route.params.id,
        page: route.query?.page,
        resource: route.query?.resource,
        highlightTodo: route.query["highlight-todo"]
          ? +route.query["highlight-todo"]
          : null
      })
    },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Advisors",
          to: { name: "Tables", query: { page: "advisors" } }
        }
      ]
    }
  },
  {
    path: "/agencies/:id",
    name: "AgencyView",
    components: { default: AdvisorProfileView, layout: AppLayout },
    props: {
      default: route => ({
        type: "Agency",
        id: +route.params.id,
        page: route.query?.page,
        resource: route.query?.resource,
        highlightTodo: route.query["highlight-todo"]
          ? +route.query["highlight-todo"]
          : null
      })
    },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Advisors",
          to: { name: "Tables", query: { page: "advisors" } }
        }
      ]
    }
  },
  // Agents/Agencies Deprecated
  {
    path: "/advisors/:type/:id",
    name: "AdvisorView",
    redirect: to => {
      if (to.params.type.includes("agenc")) {
        return {
          name: "AgencyView",
          params: { id: +to.params.id },
          query: to.query
        };
      }
      return {
        name: "AgentView",
        params: { id: +to.params.id },
        query: to.query
      };
    }
  },

  // carriers
  {
    path: "/carriers/:id",
    beforeEnter: showBacknineGuard,
    name: "CarrierView",
    components: { default: CarrierView, layout: AppLayout },
    props: {
      default: route => ({
        id: +route.params.id,
        page: route.query?.page
      })
    },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Carriers",
          to: { name: "Tables", query: { page: "carriers" } }
        }
      ]
    }
  },

  // personnel
  {
    path: "/personnel/create",
    name: "PersonnelCreate",
    components: { default: PersonnelCreate, layout: AppLayout },
    meta: {
      breadcrumb: "Create Personnel",
      breadcrumbs: [
        {
          text: "Carrier Personnel",
          to: { name: "Tables", query: { page: "carrier-personnel" } }
        }
      ]
    },
    beforeEnter: groupTwoGuard
  },
  {
    path: "/personnel/:id",
    name: "PersonnelView",
    components: { default: PersonnelView, layout: AppLayout },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Carrier Personnel",
          to: { name: "Tables", query: { page: "carrier-personnel" } }
        }
      ]
    }
  },

  // cases
  {
    path: "/cases",
    name: "CasesTable",
    components: { default: CasesTable, layout: AppLayout }
  },
  {
    path: "/cases/create",
    name: "CaseCreate",
    components: { default: CaseCreate, layout: AppLayout },
    beforeEnter: groupTwoGuard,
    meta: {
      breadcrumb: "Create Case",
      breadcrumbs: [{ text: "Cases", to: { name: "CasesTable" } }]
    }
  },
  {
    path: "/cases/informal/create",
    name: "CaseCreateInformal",
    components: { default: CaseCreateInformal, layout: AppLayout },
    meta: {
      breadcrumb: "Create Informal Case",
      breadcrumbs: [{ text: "Cases", to: { name: "CasesTable" } }]
    }
  },
  {
    path: "/cases/submit",
    name: "CaseSubmission",
    components: { default: CaseSubmission, layout: AppLayout },
    meta: {
      breadcrumb: "Case Submission",
      breadcrumbs: [{ text: "Cases", to: { name: "CasesTable" } }]
    }
  },
  {
    path: "/cases/:id",
    name: "CaseView",
    components: { default: CaseView, layout: AppLayout },
    meta: {
      useStateVal: true,
      breadcrumbs: [{ text: "Cases", to: { name: "CasesTable" } }]
    },
    props: {
      default: route => {
        let task;
        let taskId = route.query?.task;
        let taskType = route.query?.type;
        if (taskType && taskId) task = `${taskType}-${taskId}`;
        else if (taskId && taskId.includes("-")) task = taskId;
        return {
          id: +route.params.id,
          page: route.query?.page,
          task,
          highlightTodo: route.query["highlight-todo"]
            ? +route.query["highlight-todo"]
            : null
        };
      }
    }
  },

  // electronic-applications
  {
    path: "/electronic-applications/:id",
    props: {
      default: route => ({
        id: +route.params.id,
        page: route.query?.page,
        fromCase: route.params.fromCase,
        highlightTodo: route.query["highlight-todo"]
          ? +route.query["highlight-todo"]
          : null
      })
    },
    name: "ElectronicApplicationView",
    components: { default: ElectronicApplicationView, layout: AppLayout },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Quote & Apply",
          to: { name: "QuoteAndApply", query: { view: "list" } }
        }
      ]
    }
  },

  // contract parties, coloquially known as clients
  {
    path: "/clients/create",
    name: "ContractPartyCreate",
    beforeEnter: showBacknineGuard,
    components: { default: ContractPartyCreate, layout: AppLayout },
    meta: {
      breadcrumb: "Create Client",
      breadcrumbs: [
        {
          text: "Clients",
          to: { name: "Tables", query: { page: "clients" } }
        }
      ]
    }
  },
  {
    path: "/clients/:id",
    name: "ContractPartyView",
    components: { default: ContractPartyView, layout: AppLayout },
    props: {
      default: route => ({
        id: +route.params.id,
        page: route.query.page,
        highlightTodo: route.query["highlight-todo"]
          ? +route.query["highlight-todo"]
          : null
      })
    },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Clients",
          to: { name: "Tables", query: { page: "clients" } }
        }
      ]
    }
  },

  // case-management
  {
    path: "/case-management",
    name: "CaseManagement",
    components: { default: CaseManagement, layout: AppLayout }
  },

  // party view
  {
    path: "/individuals/:id",
    name: "IndividualView",
    props: { default: route => ({ id: +route.params.id, type: "Individual" }) },
    components: { default: PartyView, layout: AppLayout },
    meta: { useStateVal: true }
  },
  {
    path: "/entities/:id",
    name: "EntityView",
    props: { default: route => ({ id: +route.params.id, type: "Entity" }) },
    components: { default: PartyView, layout: AppLayout },
    meta: { useStateVal: true }
  },

  // commissions
  {
    path: "/commissions",
    name: "Commissions",
    components: { default: Commissions, layout: AppLayout },
    beforeEnter: multiguard([commissionsEnabledGuard, showBacknineGuard])
  },
  {
    path: "/commissions/payments/create",
    name: "PaymentCreate",
    components: { default: PaymentCreate, layout: AppLayout },
    meta: {
      breadcrumb: "Create Payment",
      breadcrumbs: [
        {
          text: "Commissions",
          to: { name: "Commissions", query: { page: "payments" } }
        }
      ]
    },
    beforeEnter: multiguard([accountingGuard])
  },
  {
    path: "/commissions/pay-periods/:id",
    name: "PayPeriodView",
    components: { default: PayPeriodView, layout: AppLayout },
    props: { default: true },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Commissions",
          to: { name: "Commissions", query: { page: "pay-periods" } }
        }
      ]
    },
    beforeEnter: multiguard([commissionsEnabledGuard, showBacknineGuard])
  },

  // b9
  {
    path: "/b9/commissions",
    beforeEnter: accountingGuard,
    name: "BackNineCommissionsDashboard",
    components: { default: BackNineCaseCommissionsTable, layout: AppLayout }
  },
  {
    path: "/b9/statements",
    name: "BackNineCommissionsStatements",
    components: { default: BackNineStatementsTable, layout: AppLayout },
    beforeEnter: accountingGuard
  },
  {
    path: "/b9/statements/create",
    name: "StatementCreate",
    components: { default: StatementCreate, layout: AppLayout },
    meta: {
      breadcrumb: "Create BackNine Statement",
      breadcrumbs: [
        {
          text: "BackNine Statements",
          to: { name: "BackNineCommissionsStatements" }
        }
      ]
    },
    beforeEnter: accountingGuard
  },
  {
    path: "/b9/statements/pending-transactions",
    name: "BackNinePendingTransactions",
    components: { default: QueuedTransactions, layout: AppLayout },
    meta: {
      breadcrumb: "Pending Transactions",
      breadcrumbs: [
        {
          text: "BackNine Statements",
          to: { name: "BackNineCommissionsStatements" }
        }
      ]
    },
    beforeEnter: accountingGuard
  },
  {
    path: "/b9/statements/:id",
    name: "StatementView",
    components: { default: BackNineStatement, layout: AppLayout },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "BackNine Statements",
          to: { name: "BackNineCommissionsStatements" }
        }
      ]
    },
    beforeEnter: accountingGuard
  },

  // contact
  {
    path: "/contact",
    name: "Contact",
    components: { default: BackNineContactsTable, layout: AppLayout }
  },

  // employees
  {
    path: "/employees/:id",
    props: { default: true },
    name: "EmployeeView",
    components: { default: EmployeeView, layout: AppLayout }
  },

  // transactions
  {
    path: "/transactions/create",
    name: "TransactionCreate",
    components: { default: TransactionCreate, layout: AppLayout },
    beforeEnter: accountingGuard
  },

  // products
  {
    path: "/products",
    name: "Products",
    components: { default: ProductsTable, layout: AppLayout },
    beforeEnter: multiguard([commissionsEnabledGuard, showBacknineGuard])
  },
  {
    path: "/products/create",
    name: "ProductCreate",
    components: { default: ProductCreate, layout: AppLayout },
    beforeEnter: accountingGuard,
    meta: {
      breadcrumb: "Create Product",
      breadcrumbs: [{ text: "Products", to: { name: "Products" } }]
    }
  },
  {
    path: "/products/:id",
    name: "ProductView",
    props: {
      default: route => ({
        id: +route.params.id,
        page: route.query?.page
      })
    },
    components: { default: ProductView, layout: AppLayout },
    meta: {
      useStateVal: true,
      breadcrumbs: [{ text: "Products", to: { name: "Products" } }]
    }
  },

  // marketing
  {
    path: "/marketing",
    components: { default: MarketingView, layout: AppLayout },
    name: "MarketingEmails",
    beforeEnter: hasApprovedDomainGuard
  },

  //leads
  {
    beforeEnter: groupTwoGuard,
    path: "/leads/create",
    name: "LeadCreate",
    components: { default: LeadCreate, layout: AppLayout },
    meta: {
      breadcrumb: "Create Lead",
      breadcrumbs: [
        {
          text: "Leads",
          to: { name: "Tables", query: { page: "leads" } }
        }
      ]
    }
  },
  {
    beforeEnter: groupTwoGuard,
    path: "/leads/:id",
    name: "LeadView",
    components: { default: LeadView, layout: AppLayout },
    props: {
      default: route => ({
        id: +route.params.id,
        page: route.query?.page,
        highlightTodo: route.query["highlight-todo"]
          ? +route.query["highlight-todo"]
          : null
      })
    },
    meta: {
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Leads",
          to: { name: "Tables", query: { page: "leads" } }
        }
      ]
    }
  },

  // Tables
  {
    path: "/tables",
    name: "Tables",
    components: { default: TableView, layout: AppLayout },
    props: {
      default: route => ({ page: route.query?.page })
    }
  },

  // help-center
  {
    path: "/help-center",
    name: "HelpCenter",
    components: { default: HelpCenter, layout: AppLayout }
  },
  {
    path: "/help-center/series/:id",
    name: "HelpCenterSeries",
    components: { default: HelpCenterSeries, layout: AppLayout },
    meta: {
      useStateVal: true,
      breadcrumbs: [{ text: "Help Center", to: { name: "HelpCenter" } }]
    },
    props: { default: true },
    beforeEnter: (to, from, next) => {
      const available = Object.values(AVAILABLE_SERIES);
      if (available.includes(to.params.id)) return next();
      return next({ name: "Home" });
    }
  },
  {
    path: "/help-center/articles/:id",
    name: "HelpCenterArticleViewer",
    components: { default: Reader, layout: AppLayout },
    props: { default: true },
    meta: {
      useStateVal: true,
      breadcrumbs: [{ text: "Help Center", to: { name: "HelpCenter" } }]
    }
  },
  {
    path: "/help-center/articles/:id/edit",
    name: "HelpCenterArticleEditor",
    props: { default: true },
    components: { default: HelpCenterCreator, layout: AppLayout },
    meta: {
      useStateVal: true,
      breadcrumbs: [{ text: "Help Center", to: { name: "HelpCenter" } }]
    },
    beforeEnter: groupTwoGuard
  },

  // Inbound Call
  {
    path: "/inbound-call/:phone",
    name: "InboundCallSearch",
    components: { default: InboundCall, layout: AppLayout },
    props: { default: true },
    beforeEnter: (to, from, next) => {
      const rawPhone = to.params.phone;
      if (!to.params.phone) return next({ name: "Home" });

      let phone = rawPhone.replace(/^\D+/g, "");
      if (phone.startsWith("1")) phone = phone.substr(1);

      if (phone.length < 10) return next({ name: "Home" });

      to.params.phone = phone;
      return next();
    }
  },

  {
    beforeEnter: isEmployeeGuard,
    path: "/custom-chat-templates/create",
    name: "CustomChatTemplateCreator",
    components: { default: CustomChatTemplateCreator, layout: AppLayout },
    meta: {
      useBreadcrumbSlot: true,
      breadcrumb: "Create Custom Chat Template",
      breadcrumbs: [
        {
          text: "Custom Chat Templates",
          to: { name: "Tables", query: { page: "custom-chat-templates" } }
        }
      ]
    }
  },
  {
    beforeEnter: isEmployeeGuard,
    path: "/custom-chat-templates/:id",
    name: "CustomChatTemplateEditor",
    components: { default: CustomChatTemplateCreator, layout: AppLayout },
    props: { default: r => ({ id: +r.params.id }) },
    meta: {
      useStateVal: true,
      useBreadcrumbSlot: true,
      breadcrumb: "Custom Chat Template Viewer",
      breadcrumbs: [
        {
          text: "Custom Chat Templates",
          to: { name: "Tables", query: { page: "custom-chat-templates" } }
        }
      ]
    }
  },

  // Settings
  {
    path: "/settings",
    name: "LoggedInUserSettings",
    components: { default: SettingsView, layout: BareLayout },
    props: {
      default: () => {
        const user = useUserStore();
        return { id: user.loginable.id, type: user.loginable.type };
      }
    }
  },
  {
    path: "/agencies/:id/settings",
    name: "AgencyEdit",
    components: { default: SettingsView, layout: BareLayout },
    props: {
      default: route => ({ id: +route.params.id, type: "Agency" })
    }
  },
  {
    path: "/agents/:id/settings",
    name: "AgentEdit",
    components: { default: SettingsView, layout: BareLayout },
    props: {
      default: route => ({ id: +route.params.id, type: "Agent" })
    }
  },
  // Settings - Deprecated
  {
    path: "/advisors/:type/:id/settings",
    name: "AdvisorEdit",
    redirect: to => {
      if (to.params.type.includes("agenc")) {
        return {
          name: "AgencyEdit",
          params: { id: to.params.id }
        };
      }
      return {
        name: "AgentEdit",
        params: { id: to.params.id }
      };
    }
  },
  { path: "*", redirect: "/" }
];

// Handle Duplicated navigation...https://stackoverflow.com/questions/58634914/getting-navigation-duplicated-error-while-the-route-is-being-replaced-on-vue-rou
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (!VueRouter.isNavigationFailure(err)) throw err;
  });
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => {
    if (!VueRouter.isNavigationFailure(err)) throw err;
  });
};

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});

router.beforeEach((to, from, next) => {
  const pageComparison = ({ name, params }) => JSON.stringify({ name, params });
  if (pageComparison(to) === pageComparison(from)) return next();
  if (to.meta.bypassStdAuthGuard) return next();
  return authGuard(to, from, next);
});

export default router;
